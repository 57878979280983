import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Abgeltungssteuer = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was du über die Abgeltungssteuer wissen solltest" showCalc={false} />
            <Article>
                <p>
                    Du hast vor, in Immobilien zu investieren? Das ist eine gute Idee, denn sie bleiben eine der
                    sichersten Geldanlagen. Doch sobald du Gewinne aus Kapitalerträgen erzielst, kommen auch Steuern ins
                    Spiel. Seit dem Stabilitätsgesetz von 2012 wurde der Spekulationsfrist von 10 Jahren in Österreich
                    ein Ende gesetzt. Seitdem ist der Gewinn aus dem Verkauf von Immobilien und Grundstücken sofort{" "}
                    <Link to="/artikel/steuern-beim-immobilienkauf/" target="_blank" rel="noreferrer noopener">
                        steuerpflichtig
                    </Link>
                    . Diese Steuer nennt man Abgeltungssteuer.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist die Abgeltungssteuer?</h2>
                <p>
                    Die Abgeltungssteuer ist eine Art von Einkommensteuer, die bei Gewinnen durch den Verkauf einer
                    Immobilie oder eines Grundstückes fällig wird. Der Gewinn ist also so hoch wie die Differenz deiner
                    Anschaffungskosten und deines Erlöses. Das heißt, dass die Steuer nicht für den Gesamtwert der
                    Immobilie gezahlt wird, sondern nur dein Gewinn besteuert wird.
                </p>
                <hr />

                <h2>Wozu dient die Abgeltungssteuer?</h2>
                <p>
                    Die Abgeltungssteuer wurde eingeführt, um der Immobilienspekulation etwas entgegenzusetzen. Sie wird
                    für Verkauf von Grund und Boden, für Gebäude und grundstücksgleiche Rechte wie zum Beispiel
                    Baurechte verlangt. Ausgenommen sind von der Steuer nur Verkäufe von Immobilien, die vorher dein
                    Hauptwohnsitz waren. Das gilt aber nur, wenn du zwischen Kauf und Verkauf mindestens zwei Jahre oder
                    innerhalb der letzten zehn Jahre mindestens fünf darin gelebt hast. Auch wenn du die Immobilie{" "}
                    <Link to="/artikel/bauen-oder-kaufen/" target="_blank" rel="noreferrer noopener">
                        selbst gebaut
                    </Link>{" "}
                    hast, musst du keine Abgeltungssteuer dafür zahlen, sondern lediglich für den Grund und Boden, den
                    du dafür erworben hast.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Wie viel muss ich zahlen?</h2>
                <p>
                    Wie hoch die Abgeltungsteuer ist, die du zahlen musst, hängt also von deinem erzielten Gewinn ab.
                    Die Steuer auf diesen Gewinn hat bis 2016 noch 25 Prozent betragen, wurde aber in diesem Jahr auf 30
                    Prozent angehoben. Doch auch hier gibt es Ausnahmeregelungen: Wenn du als Antragsteller*in bereits
                    weniger als 30 Prozent Steuern für dein Einkommen bezahlst, kannst du beantragen, den niedrigeren
                    Steuersatz von 25 Prozent zu zahlen.
                </p>
                <hr />

                <h2>Kann man bei der Abgeltungssteuer sparen?</h2>
                <p>
                    Du kannst dir die Abgeltungssteuer zwar nicht in diesem Sinne “sparen”, aber es gibt Situationen, in
                    denen du gar nicht verpflichtet bist, sie zu zahlen. Das ist wie bereits oben erwähnt der Fall,
                    sobald es sich bei der Immobilie um deinen Hauptwohnsitz handelt. Aber auch wenn du als
                    Geringverdiener*in giltst, bist du von der Abgeltungssteuer befreit. Dazu musst du lediglich eine
                    Nichtveranlagungsbescheinigung beim Finanzamt beantragen. Die Bescheinigung wird dir ausgestellt,
                    sobald du auch keine Einkommensteuer für diese Jahr zahlen musst. Darüber solltest du dich also
                    rechtzeitig informieren.
                </p>
                <p>
                    Am besten machst du dir einen Termin mit einer Finanzexpertin oder einem Finanzexperten aus und
                    siehst dir mit diesen deine Situation genau an, wenn du dir noch nicht sicher bist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Hier geht's zur kostenlosen Erstberatung
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"abgeltungssteuer"}></BreadcrumbList>
            <ArticleStructuredData
                page={"abgeltungssteuer"}
                heading={"Was du über die Abgeltungssteuer wissen solltest"}
            />
        </Layout>
    );
};

export default Abgeltungssteuer;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.abgeltungssteuer", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
